.new-octave-collection {
    display: block;
    background-color: #FFFFFF;
}

.collection_container {
    max-width: 94%;
    margin: 0 auto;
}

.octave_banner {
    margin-top: rem(120);

    @include respond(mobile) {
        margin-top: rem(40);
    }
}

.octave_banner_bottom {
    margin-bottom: rem(120);

    @include respond(mobile) {
        margin-bottom: rem(40);
    }
}

.new_octave_collection_section {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-image: url("/new-octave-collection/octave-banner-bg.jpg");

    .octave_collection_inner_div {
        padding-top: rem(220);
        margin-bottom: rem(80);

        @include respond(mobile) {
            padding-top: rem(160);
            margin-bottom: 0;
            padding-bottom: rem(100);
        }

        @include respond(mediumMobile) {
            padding-bottom: rem(71);
        }

        .octave_banner_logo_section {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            height: 100%;
            padding-left: 8vw;
            padding-bottom: rem(60);

            &.top_paddings {
                padding-top: 10vw;
            }

            @include respond(ipadMobile) {
                padding-left: rem(0);
            }

            @include respond(mobile) {
                padding-bottom: rem(0);
            }

            &.rarest_text {
                padding-bottom: 12vw;

                @include respond(ipadProMobile) {
                    padding-bottom: rem(10);
                }

                @include respond(ipadMobile) {
                    padding-bottom: rem(120);
                }

                @include respond(mobile) {
                    padding-bottom: rem(0);
                }
            }

            .collection_logo {
                width: rem(228) !important;
                height: rem(290) !important;

                @include respond(mobile) {
                    width: rem(210) !important;
                    height: rem(270) !important;
                }

                @include respond(mediumMobile) {
                    width: rem(122) !important;
                    height: rem(154) !important;
                }

                &.small_logo_for_banner {
                    width: 75% !important;
                    max-width: rem(500) !important;
                    height: auto !important;
                }
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(54);
                font-weight: 700;
                line-height: rem(60);
                letter-spacing: 0.01em;
                color: #FFFFFF;
                margin: 0;
                padding-top: rem(34);
                text-transform: uppercase;
                max-width: rem(600);

                @include respond(laptopdesktop) {
                    font-size: rem(38);
                    line-height: rem(50);
                }

                @include respond(ipadProMobile) {
                    font-size: rem(34);
                    line-height: rem(50);
                }

                @include respond(ipadMobile) {
                    padding-top: rem(34);
                }

                @include respond(mobile) {
                    font-size: rem(44);
                    line-height: rem(40);
                }

                @include respond(mediumMobile) {
                    font-size: rem(30);
                    line-height: rem(30);
                }
            }
        }

        .octave_product_img_section {
            display: block;

            @include respond(mobile) {
                display: none;
            }

            img {
                height: rem(679) !important;
                width: rem(420) !important;
                margin-bottom: rem(-114);

                @include respond(ipadMobile) {
                    height: rem(649) !important;
                    width: rem(390) !important;
                }

                &.rarest_banner_bottle {
                    width: 75% !important;
                    max-width: rem(500) !important;
                    height: auto !important;
                    margin-bottom: rem(100);

                    @include respond(ipadProMobile) {
                        margin-bottom: rem(0);
                    }

                    @include respond(ipadMobile) {
                        margin-bottom: rem(70);
                    }
                }
            }
        }
    }

    @include respond(desktop) {
        min-height: rem(800);
    }

    @include respond(ipadMobile) {
        min-height: rem(450);
    }
}

.black_bull_collection_logo {
    width: 100%;
    height: auto;
    max-width: rem(400);
    margin-bottom: rem(10);
}

.cask_collection_details {
    display: block;
    padding: rem(160) 0 rem(139);

    @include respond(mobile) {
        padding: rem(32) 0;
    }

    h4 {
        font-family: "EB Garamond";
        font-size: rem(54);
        font-weight: 700;
        line-height: rem(60);
        letter-spacing: 0.01em;
        margin: 0 0 rem(40);
        color: #8E6A4A;
        display: none;
        // max-width: rem(400);

        @include respond(ipadMobile) {
            max-width: 100%;
            text-align: center;
        }

        @include respond(mobile) {
            display: block;
            text-align: left;
            font-size: rem(24);
            line-height: rem(24);
            text-transform: uppercase;
            margin: 0 0 rem(20);
        }
    }

    .cask_details {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: rem(700);
        margin: 0 auto;
        height: 100%;

        &.octave_cask_details {
            text-align: center;
            max-width: rem(1100);

            .inner_div {
                h3 {
                    max-width: rem(1100);
                    text-align: center;
                }
            }
        }

        @include respond(ipadMobile) {
            max-width: 100%;
            padding-bottom: rem(80);
        }

        @include respond(mobile) {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: rem(15);
            padding-bottom: rem(12);
        }

        .read_more_cask_details {
            font-family: "Raleway";
            font-size: rem(15);
            font-weight: 400;
            line-height: rem(31);
            margin: 0;
            color: #8E6A4A;
        }

        .cask_mobile_img {
            display: none;

            @include respond(mobile) {
                display: block;
            }

            img {
                width: auto !important;
                height: 75vw !important;
            }
        }

        .inner_div {
            display: block;

            @include respond(mobile) {
                text-align: left;
            }

            &.short_text {
                overflow: auto;

                @include respond(mobile) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                }

            }

            &.long_text {
                overflow: auto;
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(54);
                font-weight: 700;
                line-height: rem(55);
                letter-spacing: 0.01em;
                margin: 0 0 rem(40);
                color: #8E6A4A;
                text-transform: uppercase;
                // max-width: rem(400);

                &.for_desktop {
                    display: block;

                    @include respond(mobile) {
                        display: none;
                    }

                }

                @include respond(ipadMobile) {
                    max-width: 100%;
                    text-align: center;
                }

                @include respond(mobile) {
                    text-align: left;
                    font-size: rem(24);
                    line-height: rem(24);
                    text-transform: uppercase;
                    margin: 0 0 rem(20);
                }
            }

            p {
                font-family: 'Raleway';
                font-size: rem(20);
                font-weight: 400;
                line-height: tem(31);
                margin: 0 0 rem(40);
                color: #8F8C86;

                @include respond(ipadMobile) {
                    text-align: center;
                }

                @include respond(mobile) {
                    text-align: left;
                    font-size: rem(15);
                    line-height: rem(24);
                    margin: 0 0 rem(20);
                }
            }

            h3 {
                font-family: 'Raleway';
                font-size: rem(28);
                font-style: italic;
                font-weight: 400;
                line-height: rem(35);
                letter-spacing: 0.02em;
                margin: 0;
                color: #8E6A4A;
                max-width: rem(600);

                &.desktop-text {
                    display: block;
                }

                @include respond(ipadMobile) {
                    max-width: 100%;
                    text-align: center;
                }

                @include respond(mobile) {
                    text-align: left;
                    font-size: rem(18);
                    line-height: rem(18);

                    &.desktop-text {
                        display: none;
                    }
                }
            }
        }
    }

    .cask_collection_detail_img {
        display: block;

        img {
            width: 100% !important;
            height: auto !important;
        }

        h3 {
            font-family: 'Raleway';
            font-size: rem(18);
            font-style: italic;
            font-weight: 400;
            line-height: rem(19);
            letter-spacing: 0.02em;
            margin: 0;
            color: #8E6A4A;
            max-width: rem(600);
            display: none;

            @include respond(mobile) {
                display: block;
                max-width: rem(390);
                margin: rem(20) 0 rem(0);
            }
        }
    }
}

.invigorated_details_section {
    display: block;
    padding: 0 0 rem(133);

    @include respond(mobile) {
        padding: 0 0 rem(40);
    }

    h2 {
        font-family: "EB Garamond";
        font-size: rem(62);
        font-weight: 700;
        line-height: rem(56);
        letter-spacing: 0.01em;
        margin: 0 0 rem(66);
        color: #2B2928;
        text-transform: capitalize;
        text-align: center;
        // max-width: rem(553);

        @include respond(mobile) {
            font-size: rem(34);
            line-height: rem(30);
            // max-width: rem(300);
            margin: 0 0 rem(20);
        }
    }

    .invigorated_details_card_section {
        display: block;

        .mobile_gap {
            gap: rem(35) 0;
        }

        .column_cards_section {
            &:last-child {
                .invigorated_card {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        .invigorated_card {
            display: block;
            background: #FCF6E2;
            border-radius: rem(8);
            padding: rem(45);
            text-align: center;
            position: relative;
            height: 100%;

            @include respond(mobile) {
                padding: rem(30);
            }

            &::before {
                content: "";
                position: absolute;
                width: 76px;
                height: 39px;
                background-image: url("/images/card-connect-arrow.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                top: 25%;
                right: -76px;
                z-index: 1;

                @include respond(ipadMobile) {
                    background-image: url("/images/bottom-arrow.png");
                    top: inherit;
                    bottom: -60px;
                    right: inherit;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 30px;
                    height: 60px;
                }
            }

            .top_dots {
                &::after {
                    content: "";
                    position: absolute;
                    top: rem(20);
                    left: rem(20);
                    width: rem(14);
                    height: rem(14);
                    background: #D6C59A;
                    border-radius: 100%;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: rem(20);
                    right: rem(20);
                    width: rem(14);
                    height: rem(14);
                    background: #D6C59A;
                    border-radius: 100%;
                }
            }

            .inner_dots {
                display: block;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: rem(20);
                    left: rem(20);
                    width: rem(14);
                    height: rem(14);
                    background: #D6C59A;
                    border-radius: 100%;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: rem(20);
                    right: rem(20);
                    width: rem(14);
                    height: rem(14);
                    background: #D6C59A;
                    border-radius: 100%;
                }

            }

            .invigorated_img_div {
                display: block;
                height: rem(150);
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: rem(148) !important;
                }
            }

            .invigorated_details {
                display: block;

                h3 {
                    font-family: "Raleway";
                    font-size: rem(24);
                    font-weight: 600;
                    line-height: rem(32);
                    text-align: center;
                    color: #0D0D0D;
                    text-transform: uppercase;
                    margin: rem(43) 0 rem(24);

                    @include respond(mobile) {
                        margin: rem(20) 0;
                    }
                }

                p {
                    font-family: "Raleway";
                    font-size: rem(14);
                    font-weight: 500;
                    line-height: rem(24);
                    margin: 0;
                }
            }
        }

    }
}


.the_journey_details_section {
    display: block;
    height: 50vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    @include respond(mobile) {
        height: 85vh;
        background-position: bottom center;
        padding: rem(50) rem(16);
    }

    @include respond(mediumMobile) {
        height: rem(761);
    }

    .mobile_journey_image {
        display: none;
        text-align: center;
        margin-top: rem(102);

        @include respond(mobile) {
            display: block;
        }

        img {
            width: 100% !important;
            height: auto !important;
            max-width: rem(500) !important;
            margin: 0 auto;
        }

    }

    .journey_details_div {
        display: block;
        position: absolute;
        top: 50%;
        left: 8%;
        transform: translateY(-50%);
        max-width: 30vw;

        @include respond(ipadProMobile) {
            max-width: rem(447);
            // left: 2%;
        }

        @include respond(ipadMobile) {
            max-width: rem(307);
            left: 5%;
        }

        @include respond(mobile) {
            position: relative;
            top: inherit;
            left: inherit;
            transform: none;
            max-width: 100%;
            text-align: center;
        }


        h2 {
            font-family: "EB Garamond";
            font-size: rem(62);
            font-weight: 700;
            line-height: rem(56);
            letter-spacing: 0.01em;
            text-transform: uppercase;
            margin: 0 0 rem(42);
            color: #FDFBF6;
            max-width: rem(450);

            @include respond(ipadMobile) {
                font-size: rem(42);
                margin: 0 0 rem(22);
                line-height: rem(36);
            }

            @include respond(mobile) {
                font-size: rem(24);
                font-weight: 500;
                line-height: rem(30);
                margin: 0 auto rem(25);
                text-transform: uppercase;
            }

        }

        p {
            font-family: "Raleway";
            font-size: rem(20);
            font-weight: 400;
            line-height: rem(25);
            text-align: left;
            margin: 0 0 rem(32);
            color: #FFFFFF;

            @include respond(ipadMobile) {
                font-size: rem(14);
                margin: 0 0 rem(22);
                line-height: rem(18);
            }

            @include respond(mobile) {
                font-size: rem(13);
                line-height: rem(20);
                text-align: center;
                max-width: rem(500);
                margin: 0 auto rem(30);
            }
        }

        .btn_explore {
            width: rem(265);
            height: rem(53);
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Raleway";
            font-size: rem(14);
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.13em;
            color: #181411;
            border-radius: rem(4);
            border: 0;
            background: #FFF;

            @include respond(ipadMobile) {
                font-size: rem(12);
                width: rem(225);
                height: rem(43);
            }

            @include respond(mobile) {
                font-size: rem(12);
                line-height: rem(20);
                text-align: center;
                max-width: rem(500);
                margin: 0 auto;
            }
        }
    }
}

.featured_banner_section {
    display: block;
    padding-bottom: rem(118);

    @include respond(mobile) {
        padding-bottom: rem(0);
    }

    .collection_container {
        @include respond(mobile) {
            max-width: 100%;
        }
    }

    .featured_banner_bg_div {
        background: #171716;
        height: 40vw;
        position: relative;

        @include respond(mediumMobile) {
            height: rem(242);
        }

        @include respond(smallmobile) {
            height: rem(180);
        }

        .octave_collection_img {
            width: auto !important;
            height: 40vw !important;
            position: absolute;
            top: 0;
            right: 0;

            @include respond(mediumMobile) {
                height: rem(242) !important;
            }

            @include respond(smallmobile) {
                height: rem(180) !important;
            }

            &.desktop-img {
                display: block;


                @include respond(mobile) {
                    display: none;
                }
            }

            &.mobile-img {
                display: none;

                @include respond(mobile) {
                    display: block;
                }
            }
        }

        .featured_octave_details_section {
            display: block;
            max-width: rem(397);
            position: absolute;
            left: 10vw;
            bottom: 10vw;

            @include respond(laptopdesktop) {
                max-width: rem(500);
                left: 7vw;
                bottom: 6vw;
            }

            @include respond(ipadProMobile) {
                max-width: rem(500);
                left: rem(80);
                bottom: rem(80);
            }

            @include respond(ipadMobile) {
                max-width: rem(300);
                left: rem(40);
                bottom: rem(40);
            }

            @include respond(mediumMobile) {
                max-width: rem(160);
            }

            @include respond(smallmobile) {
                left: rem(20);
                bottom: rem(10);
            }

            h5 {
                font-family: "Raleway";
                font-size: rem(18);
                font-weight: 500;
                line-height: rem(24);
                margin: 0;
                color: #FFFFFF;

                @include respond(mediumMobile) {
                    font-size: rem(12);
                    line-height: rem(16);
                }
            }

            h2 {
                font-family: "EB Garamond";
                font-size: rem(62);
                font-weight: 700;
                line-height: rem(56);
                letter-spacing: 0.01em;
                text-transform: capitalize;
                margin: rem(12) 0 rem(40);
                color: #FDFBF6;

                @include respond(ipadMobile) {
                    font-size: rem(35);
                    line-height: rem(36);
                    margin: rem(6) 0 rem(25);
                }

                @include respond(mediumMobile) {
                    font-size: rem(24);
                    line-height: rem(24);
                    margin: rem(8) 0 rem(18);
                }
            }

            .btn_featured_explore {
                width: rem(275);
                height: rem(60);
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Raleway";
                font-size: rem(14);
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.13em;
                color: #FFFFFF;
                border-radius: rem(1);
                background: #8E6A4A;
                border: 0;
                outline: none;
                box-shadow: none;

                @include respond(ipadMobile) {
                    width: rem(225);
                    height: rem(40);
                    font-size: rem(12);
                }

                @include respond(mediumMobile) {
                    width: rem(120);
                    height: rem(35);
                    font-size: rem(10);
                }
            }
        }
    }
}


.whisky_details_card_section {
    display: block;
    padding: rem(120) 0;

    @include respond(mobile) {
        padding: rem(40) 0 rem(40) rem(24);
    }

    .desktop {
        display: block;

        @include respond(mobile) {
            display: none;
        }
    }

    .mobile_slider {
        display: none;

        @include respond(mobile) {
            display: block;
        }

        .slick-slider {


            .slick-list {
                .slick-track {
                    display: flex;
                }

                .slick-active {
                    width: calc(100vw - 25vw) !important
                }
            }
        }
    }

    .whisky_card {
        display: block;
        height: 100%;

        @include respond(mobile) {
            padding-right: rem(24);
        }

        .whisky_img_div {
            display: block;
            background: #F5F1EB;
            padding: rem(30);
            height: 29vw;

            @include respond(mobile) {
                height: 70vw;
                padding: rem(24);
            }

            @include respond(mediumMobile) {
                height: 65vw;
                padding: rem(24);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100% !important;
                height: 100% !important;
            }
        }

        .whisky_details {
            display: block;
            padding-top: rem(24);
            height: calc(100% - 29vw);
            display: flex;
            flex-wrap: wrap;

            .whisky_data {
                display: block;
                text-align: center;
                width: 100%;

                h2 {
                    font-family: "Raleway";
                    font-size: rem(17);
                    font-weight: 500;
                    line-height: rem(23);
                    text-align: center;
                    color: #000000;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond(ipadMobile) {
                        font-size: rem(15);
                        line-height: rem(19);
                    }

                    @include respond(mediumMobile) {
                        text-align: left;
                        font-size: rem(15);
                    }
                }

                h3 {
                    font-family: "EB Garamond";
                    font-size: rem(28);
                    font-weight: 800;
                    line-height: rem(39);
                    color: #000000;
                    text-transform: uppercase;
                    margin: 0;

                    @include respond(ipadProMobile) {
                        font-size: rem(24);
                        line-height: rem(29);
                    }

                    @include respond(ipadMobile) {
                        font-size: rem(20);
                        line-height: rem(25);
                    }

                    @include respond(mediumMobile) {
                        text-align: left;
                        font-size: rem(18);
                        font-weight: 500;
                    }
                }
            }

            .whisky_btn_group {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: rem(12);
                width: 100%;

                @include respond(mediumMobile) {
                    margin-top: rem(8);
                    justify-content: left;
                }

                .btn_buy_now {
                    width: rem(161);
                    height: rem(40);
                    border: rem(2) solid #8E6A4A;
                    color: #8E6A4A;
                    font-family: "Raleway";
                    font-size: rem(18);
                    font-weight: 500;
                    line-height: normal;
                    text-align: center;
                    border-radius: rem(4);
                    box-shadow: none;
                    outline: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include respond(ipadMobile) {
                        width: rem(120);
                        height: rem(35);
                        font-size: rem(15);
                    }

                    @include respond(mediumMobile) {
                        width: rem(131);
                        height: rem(32);
                        border: rem(1) solid #8E6A4A;
                        font-size: rem(14);
                        line-height: rem(19);
                    }
                }
            }
        }
    }
}

.the_whisky_collection_section {
    display: block;
    margin-bottom: rem(64);

    @include respond(mobile) {
        margin-bottom: rem(20);
    }

    .collection_title {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #8E6A4A;
        padding: rem(19) 0;
        font-family: "EB Garamond";
        font-size: rem(44);
        font-weight: 700;
        line-height: rem(60);
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #FFFFFF;

        @include respond(mobile) {
            font-size: rem(32);
        }

        @include respond(smallmobile) {
            font-size: rem(26);
        }
    }

    .collection_card_section {
        display: block;

        h2 {
            font-family: "Raleway";
            font-size: rem(24);
            font-weight: 800;
            line-height: rem(39);
            text-align: center;
            margin: rem(56) 0;
            color: #434241;
            background: linear-gradient(90deg, rgba(240, 238, 233, 0) 2.47%, #F0EEE9 20.13%, #F0EEE9 78.07%, rgba(240, 238, 233, 0) 100%);
            text-transform: uppercase;

            @include respond(mobile) {
                margin: rem(35) 0;
            }
        }

        .collection_card {
            height: 100%;
            text-align: center;

            .collection_card_img {
                display: block;
                height: 30vw !important;

                @include respond(mobile) {
                    height: 75vw !important;
                }

                img {
                    width: auto !important;
                    max-width: 95% !important;
                    margin: 0 auto;
                    height: 30vw !important;
                    transform: scale(1);
                    transition: transform .3s ease-out;

                    &:hover {
                        transform: scale(1.05);
                        transition: transform .3s ease-out;
                        transform: scale(1.05);
                    }

                    @include respond(mobile) {
                        height: 75vw !important;
                    }
                }
            }

            .collection_card_details {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                margin-top: rem(44);
                height: calc(100% - 34vw);

                @include respond(mobile) {
                    margin-top: rem(20);
                    height: calc(100% - 80vw);
                }

                .collection_whisky_data {
                    display: block;
                    width: 100%;

                    h4 {
                        font-family: "Raleway";
                        font-size: rem(17);
                        font-weight: 500;
                        line-height: rem(23);
                        color: #000000;
                        margin: 0;
                        text-transform: uppercase;

                        @include respond(mobile) {
                            font-size: rem(12);
                        }
                    }

                    h3 {
                        font-family: "EB Garamond";
                        font-size: rem(28);
                        font-weight: 800;
                        line-height: rem(39);
                        margin: rem(2) 0 rem(12);
                        text-transform: uppercase;
                        color: #000000;

                        @include respond(mobile) {
                            font-size: rem(18);
                            line-height: rem(23);
                            margin: 0 0 rem(8);
                        }
                    }
                }

                .collection_btn_group {
                    width: 100%;

                    .btn_view_range {
                        width: rem(161);
                        height: rem(40);
                        border: 2px solid #8E6A4A;
                        color: #8E6A4A;
                        background: #FFFFFF;
                        padding: 0;
                        outline: none;
                        font-family: "Raleway";
                        font-size: rem(18);
                        font-weight: 500;
                        line-height: normal;
                        border-radius: rem(4);
                        margin-bottom: rem(102);

                        @include respond(mobile) {
                            width: rem(131);
                            height: rem(32);
                            border: 1px solid #8E6A4A;
                            margin-bottom: rem(60);
                            font-size: rem(14);
                        }
                    }
                }
            }
        }
    }
}


.collection_popup_section {
    width: 100%;
    margin-bottom: rem(15);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .collection_popup_inner_div {
        display: block;
        background: #8A6B3C;
        border-radius: rem(8);
        padding: rem(7);

        .collection_popup_header {
            padding: rem(19) rem(32);
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include respond(mobile) {
                padding: 0 rem(10) rem(10);
            }

            h5 {
                font-family: "EB Garamond";
                font-size: rem(32);
                font-weight: 800;
                line-height: rem(39);
                color: #FFFFFF;
                text-transform: uppercase;
                margin: 0;

                @include respond(mobile) {
                    font-size: rem(18);
                }
            }

            .close_icon_div {
                display: block;

                img {
                    width: rem(25);
                    height: rem(25);
                    filter: invert(1);
                    cursor: pointer;

                    @include respond(mobile) {
                        width: rem(14);
                        height: rem(14);
                    }
                }
            }
        }

        .collection_popup_body {
            display: block;
            background: #FFFFFF;
            border-radius: rem(12);
            padding: rem(50);

            @include respond(mediumMobile) {
                padding: rem(16);
            }

            .collection_popup_card {
                display: flex;
                height: 100%;
                align-items: baseline;
                gap: rem(10);
                padding: rem(25) 0;

                @include respond(smallmobile) {
                    gap: rem(4);
                }

                .collection_popup_card_img {
                    display: block;
                    height: 25vw !important;

                    @include respond(ipadProMobile) {
                        height: 25vw !important;
                    }

                    @include respond(ipadMobile) {
                        height: 27vw !important;
                    }

                    @include respond(smallmobile) {
                        width: 100%;
                    }

                    img {
                        width: auto !important;
                        max-width: 95% !important;
                        margin: 0 auto;
                        height: 25vw !important;

                        @include respond(ipadProMobile) {
                            height: 25vw !important;
                        }

                        @include respond(ipadMobile) {
                            height: 27vw !important;
                        }
                    }
                }

                .collection_card_popup_details {
                    max-width: rem(111);
                    margin-bottom: rem(15);

                    .collection_whisky_popup__data {
                        display: block;
                        width: 100%;

                        h4 {
                            font-family: "Raleway";
                            font-size: rem(17);
                            font-weight: 500;
                            line-height: rem(20);
                            color: #000000;
                            margin: 0;
                            text-transform: uppercase;

                            @include respond(mediumMobile) {
                                font-size: rem(13);
                                line-height: rem(15);
                            }

                            @include respond(smallmobile) {
                                font-size: rem(9);
                                line-height: normal;
                            }
                        }

                        h3 {
                            font-family: "EB Garamond";
                            font-size: rem(28);
                            font-weight: 800;
                            line-height: rem(39);
                            margin: rem(12) 0 0;
                            text-transform: uppercase;
                            color: #000000;
                        }

                        .view_more_for_octave_collection {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: rem(8) rem(4);
                            border: rem(1) solid #8E6A4A;
                            border-radius: rem(4);
                            background: #FFF;
                            font-family: "Raleway";
                            font-size: rem(12);
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                            color: #8E6A4A;

                            @include respond(smallmobile) {
                                font-size: rem(10);
                                line-height: normal;
                                padding: rem(4);
                            }
                        }
                    }
                }
            }
        }
    }
}

.inner_text_logo {
    width: 100%;
}